import React, { useState, useEffect } from "react"
import { FacebookProvider, Page } from 'react-facebook';
import Helmet from "react-helmet"
import { withPrefix } from "gatsby";
import Logo from "../images/logo.png"
import Kruta1 from "../images/kruticka_orez.png"
import Kruta2 from "../images/kruticka2_orez.png"
import Kruta3 from "../images/kruticka3_orez.png"
import Kruta4 from "../images/kruticka4_orez.png"
import Kure0 from "../images/kure0.png"
import Kure1 from "../images/kure1.png"
import Kure2 from "../images/kure2.png"
import Kure3 from "../images/kure3.png"
import BG from "../images/bg_full.jpg";

export default function Home() {
  const [fullName, setFullName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState("");
  const [krutyCerven, setKrutyCerven] = useState(0);
  const [krutySrpen, setKrutySrpen] = useState(0);
  const [krutyZari, setKrutyZari] = useState(0);
  const [krutyListopad, setKrutyListopad] = useState(0);
  const [kurataKveten, setKurataKveten] = useState(0);
  const [kurataCerven, setKurataCerven] = useState(0);
  const [kurataCervenec, setKurataCervenec] = useState(0);
  const [kurataSrpen, setKurataSrpen] = useState(0);
  const [kurataZari, setKurataZari] = useState(0);
  const [kurataRijen, setKurataRijen] = useState(0);
  const [kurataListopad, setKurataListopad] = useState(0);
  const [loading, setLoading] = useState(false);

  const [krutyCervenLimit, setKrutyCervenLimit] = useState(0);
  const [krutySrpenLimit, setKrutySrpenLimit] = useState(0);
  const [krutyZariLimit, setKrutyZariLimit] = useState(0);
  const [krutyListopadLimit, setKrutyListopadLimit] = useState(0);
  const [kurataKvetenLimit, setKurataKvetenLimit] = useState(0);
  const [kurataCervenLimit, setKurataCervenLimit] = useState(0);
  const [kurataCervenecLimit, setKurataCervenecLimit] = useState(0);
  const [kurataSrpenLimit, setKurataSrpenLimit] = useState(0);
  const [kurataZariLimit, setKurataZariLimit] = useState(0);
  const [kurataRijenLimit, setKurataRijenLimit] = useState(0);
  const [kurataListopadLimit, setKurataListopadLimit] = useState(0);
  const [height, setHeight] = useState(0);

  const [fbIsHidden, toggleFB] = useState(true);

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    let order = {
      'celeJmeno': fullName,
      'telefon': phone,
      email,
      'zprava': message,
      krutyCerven,
      krutySrpen,
      krutyZari,
      krutyListopad,
      kurataKveten,
      kurataCerven,
      kurataCervenec,
      kurataSrpen,
      kurataZari,
      kurataRijen,
      kurataListopad,
      'newOrder': true
    };

    let response = await fetch('https://api.farmaumateje.cz/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(order)
    });

    let result = await response.json();
    if(result.sent === true) {
      window.location.replace("/objednavka-prijata");
    }
  }

  const getCounts = async () => {
    setLoading(true);

    let payload = {
      'APIKEY': '8d02f430-3630-42ff-9744-28234ecf34b7',
      'newOrder': false
    };

    let response = await fetch('https://api.farmaumateje.cz/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    let result = await response.json();
   if(result.results) {
     setKrutyCervenLimit(result.results[0].krutyCerven);
     setKrutySrpenLimit(result.results[0].krutySrpen);
     setKrutyZariLimit(result.results[0].krutyZari);
     setKrutyListopadLimit(result.results[0].krutyListopad);
     setKurataKvetenLimit(result.results[0].kurataKveten);
     setKurataCervenLimit(result.results[0].kurataCerven);
     setKurataCervenecLimit(result.results[0].kurataCervenec);
     setKurataSrpenLimit(result.results[0].kurataSrpen);
     setKurataZariLimit(result.results[0].kurataZari);
     setKurataRijenLimit(result.results[0].kurataRijen);
     setKurataListopadLimit(result.results[0].kurataListopad);

     setLoading(false);
   }
  }

  useEffect(() => {
    getCounts();
    const isServer = typeof window === 'undefined';
    if(!isServer) {
      setHeight(window.innerHeight);
    }
  }, []);


  return (
    <div style={{
        backgroundImage: 'url('+BG+')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'bottom'
    }}>
      <Helmet>
        <title>Farma u Matěje</title>
        <script src={withPrefix('../../scripts/jquery.min.js')} type="text/javascript" />
        <script src={withPrefix('../../scripts/jquery.scrollex.min.js')} type="text/javascript" />
        <script src={withPrefix('../../scripts/jquery.scrolly.min.js')} type="text/javascript" />
        <script src={withPrefix('../../scripts/browser.min.js')} type="text/javascript" />
        <script src={withPrefix('../../scripts/breakpoints.min.js')} type="text/javascript" />
        <script src={withPrefix('../../scripts/util.js')} type="text/javascript" />
        <script src={withPrefix('../../scripts/main.js')} type="text/javascript" />

        <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('../../apple-touch-icon.png')}/>
        <link rel="icon" type="image/png" sizes="32x32" href={withPrefix('../../favicon-32x32.png')}/>
        <link rel="icon" type="image/png" sizes="16x16" href={withPrefix('../../favicon-16x16.png')}/>
        <link rel="manifest" href={withPrefix('../../site.webmanifest')}/>
      </Helmet>
      <div id="wrapper">
        <div className={`fb ${fbIsHidden && 'hidden'}`}>
          <FacebookProvider appId="1285406011806580">
            <Page href="https://www.facebook.com/farmaumateje" tabs="timeline" height={height} />
          </FacebookProvider>
        </div>

        <button className="loginBtn loginBtn--facebook" onClick={() => toggleFB(!fbIsHidden)}>
          Farma u Matěje
        </button>

        <button className={`loginBtn loginBtn--facebook hideFB ${!fbIsHidden && 'visibleButton'}`} onClick={() => toggleFB(true)}>
          Schovat panel
        </button>

        <header id="header" className="alt">
          <h1>FARMA U MATĚJE</h1>
          <p>Vaše rodinná farma z Východních Čech</p>
        </header>

        <nav id="nav">
          <ul>
            <li><a href="#intro" rel="noopener noreferrer nofollow" className="active">Úvod</a></li>
            <li><a href="#o-nas">O nás</a></li>
            <li><a href="#vize">Vize</a></li>
            <li><a href="#kruty">Krůty</a></li>
            <li><a href="#kurata">Kuřata</a></li>
            <li><a href="#kontakt">Kontakt</a></li>
            <li><a href="#objednavky">Objednávky</a></li>
          </ul>
        </nav>

        <div id="main">

          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Rodinná farma, v souladu s přírodou, zaměření na kvalitu!</h2>
                </header>
                <p>Jsme rodinou farmou z malé vesničky u Lanškrouna. Pro své zákazníky chováme převážně drůbež,
                  která u nás roste v souladu s přírodou, ve volných výbězích, zeleném porostu a čerstvém vzduchu.</p>
              </div>
              <span className="image"><img src={Logo} alt="" /></span>
            </div>
          </section>

          <section id="o-nas" className="main special">
            <header className="major">
              <h2>O nás</h2>
            </header>
            <p>Náš příběh začíná v roce 2018, v malé vesničce u Lanškouna, jménem Lubník. Blížil se termín svatby a při
              sestavování
              svatebního menu jsme si řekli, že by bylo hezké, vykrmit si na hostinu vlastní krůtí maso. Do provizorního
              výběhu jsme
              horko těžko sehnali 5 mladých krůt a začali se starat. Vše dobře dopadlo a když jsme si tak pochutnali,
              pořídili jsme si
              další. Nebyli jsme však jediní, komu chutnalo, a tak jsme se rozhodli pořídit další. A to byl teprve
              začátek… Když se totiž
              potká firemní bankéř, manažer výroby, fotografka a učitelka, je jasné, že zemědělství je jasná volba!
              Přečetli jsme spousty knih, doplnili si vzdělání na Střední škole zemědělské a veterinární v Lanškrouně,
              navštívili několik
              farem, odchoven a zemědělců, abychom nakonec zjistili, že příroda pomáhat nepotřebuje. Farma je založena
              na
              jednoduchých principech: příroda, kvalita a rodina. O tom, jak chováme jednotlivá zvířata se můžete dočíst
              dále.</p>
          </section>

          <section id="vize" className="main special">
            <header className="major">
              <h2>Vize</h2>
            </header>
            <p className="content">
              Naší dlouhodobou vizí je vytvoření místa, které pro Vás zajistí kromě odchovu kuřat a krůt, také
              například odchov hus a křepelek. Chtěli bychom se postupně pustit do výroby potravin z našich
              vlastních chovů a připravovat pro Vás sortiment jako například: vývary, paštiky, párečky, marinované
              grilovací sety nebo domácí vajíčka.
              K tomuto cíli nás čeká ještě dlouhá cesta, sbírání mnoha zkušeností, vyhovění velké spoustě
              legislativních požadavků a v neposlední řadě tuny entuziasmu. Držte nám palce a sledujte novinky na
              našich facebookových stránkách!
            </p>
          </section>

          <section id="kruty" className="main special">
            <header className="major">
              <h2>Krůty</h2>
            </header>
            <ul className="features">
              <li data-aos="fade-right">
              <span className="major style1 circle-img">
                <img src={Kruta1} alt="kruta1" />
              </span>
                <h3><b>Vlastní odchov</b></h3>
                <p>Řádně si vybíráme
                  dodavatele a jezdíme
                  se dívat do jejich
                  provozů. Krůty a
                  krocany si dovážíme
                  v cca 7 týdnu jejich
                  života.
                </p>
              </li>
              <li data-aos="fade-down">
               <span className="major style1 circle-img">
                <img src={Kruta2} alt="kruta1" />
              </span>
                <h3><b>Prostředí</b></h3>
                <p>
                  Po týdenní karanténě
                  se vydávají ven,
                  poznávat slunce, trávu,
                  hmyz a dopřávat si
                  pohybu.
                </p>
              </li>
              <li data-aos="fade-down">
               <span className="major style1 circle-img">
                <img src={Kruta3} alt="kruta1" />
              </span>
                <h3><b>Kvalita</b></h3>
                <p>
                  Taktéž zde klademe
                  velký důraz na čistotu
                  výběhů i odchoven po
                  celých 22 týdnů jejich
                  života.
                </p>
              </li>
              <li data-aos="fade-left">
               <span className="major style1 circle-img">
                <img src={Kruta4} alt="kruta1" />
              </span>
                <h3><b>Zákazník</b></h3>
                <p>
                  Zákazníci od nás dostávají
                  krůtu a krocana vyvrhnuté,
                  důkladně očištěné, a
                  vychlazené. Krůty váží cca
                  11kg a krocani cca 16,5kg
                </p>
              </li>
            </ul>
          </section>
          <section id="kurata" className="main special">
            <header className="major">
              <h2>Kuřata</h2>
            </header>
            <ul className="features">
              <li data-aos="fade-right">
              <span className="major style1 circle-img">
                <img src={Kure0} alt="kure0" />
              </span>
                <h3><b>Vlastní odchov</b></h3>
                <p>Správné zacházení a
                  výživu si hlídáme již
                  od prvního dne ve
                  vlastních odchovnách.
                </p>
              </li>
              <li data-aos="fade-down">
               <span className="major style1 circle-img">
                <img src={Kure1} alt="kruta1" />
              </span>
                <h3><b>Prostředí</b></h3>
                <p>
                  Po 21 dnech se
                  vydávají ven, poznávat
                  slunce, trávu, hmyz a
                  dopřávat si pohybu.
                </p>
              </li>
              <li data-aos="fade-down">
               <span className="major style1 circle-img">
                <img src={Kure2} alt="kruta1" />
              </span>
                <h3><b>Kvalita</b></h3>
                <p>
                  Velký důraz klademe
                  na čistotu výběhů i
                  odchoven po celých 80
                  dní jejich života.
                </p>
              </li>
              <li data-aos="fade-left">
               <span className="major style1 circle-img">
                <img src={Kure3} alt="kruta1" />
              </span>
                <h3><b>Zákazník</b></h3>
                <p>
                  Zákazníci od nás
                  dostávají kuře
                  vyvrhnuté, důkladně
                  očištěné, a vychlazené.
                  Průměrná váha kuřete
                  je cca 2,8kg.
                </p>
              </li>
            </ul>
          </section>


          <section id="kontakt" className="main special">
            <header className="major">
              <h2>Ozvěte se nám!</h2>
            </header>

            <div className="contact-row">
              <div className="span12">
                <p>
                  <b>Sídlo společnosti a provozovna:</b><br />
                  Lubník 43, Lanškroun 563 01
                </p>
              </div>
              <div className="span12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2570.1619575931227!2d16.65350201576205!3d49.89576263505724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4712098bf54aaf27%3A0x74b4f9834350a56!2zTHVibsOtayA0MywgNTYzIDAxIEx1Ym7DrWs!5e0!3m2!1scs!2scz!4v1603367802244!5m2!1scs!2scz"
                  width="100%" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false"
                  title="mapa" />
              </div>
              <div className="clearfix" />
            </div>

            <ul className="features">
              <li data-aos="fade-right">
                <h4><b>Farma</b></h4>
                <span>Petr Matějka</span><br />
                <a href="mailto: info@farmaumateje.cz">info@farmaumateje.cz</a><br />
                <a href="tel: 731602099">723 150 753</a>
              </li>
              <li data-aos="fade-down">
                <h4><b>Zákazníci</b></h4>
                <span>Žaneta Matějková</span><br />
                <a href="mailto: objednavky@farmaumateje.cz">objednavky@farmaumateje.cz</a><br />
                <a href="tel: 775323564">775 323 564</a>
              </li>
              <li data-aos="fade-left">
                <h4><b>Objednávky</b></h4>
                <a href="#objednavky">objednávky zde</a><br />
              </li>
            </ul>
            <header className="major" id="objednavky">
              <h2>Objednávka</h2>
            </header>

            <form className="Contact-form" name="Objednavky" method="post" onSubmit={e => submitForm(e)}>
              <input type="hidden" name="form-name" value="Objednavky" />
              <div className="twoColumns">
                <div>
                  <input type="text" placeholder="Jméno a přijmení" name="name" onChange={e => setFullName(e.target.value)} required />
                  <input type="email" placeholder="E-mail" name="e-mail" onChange={e => setEmail(e.target.value)} required />
                  <input type="text" placeholder="Telefon" name="phone" onChange={e => setPhone(e.target.value)} required />
                </div>
                <div>
                   <textarea rows="6" placeholder="Zde prosím uveďte, zdali preferujete například krocana před krůtou, nebo jiné
                    přání, či dotaz. Potvrzení objednávky Vám dorazí na email a na Vaši poznámku zareagujeme též emailem."
                             name="message" onChange={e => setMessage(e.target.value)} />
                </div>
              </div>

              <div className="columns">
                <section>
                <h2>Krůty</h2>
                <div>
                  <span>
                  <label htmlFor="kruty-cerven">Červen {krutyCervenLimit <= 0 && "(Vyprodáno)"}</label>
                    {krutyCervenLimit > 0 &&
                    <input type="number" pattern="[0-9]*" min={0} id="kruty-cerven" tabIndex={krutyCervenLimit <= 0 && -9999}
                           max={krutyCervenLimit} placeholder="Počet" className={krutyCervenLimit <= 0 && 'disabled'}
                           onChange={e => setKrutyCerven(krutyCervenLimit <= 0 ? 0 : e.target.value)}
                           name="kruty-cerven" />
                    }
                    </span>

                  <span>
                  <label htmlFor="kruty-srpen">Srpen {krutySrpenLimit <= 0 && "(Vyprodáno)"}</label>
                    {krutySrpenLimit > 0 &&
                    <input type="number" pattern="[0-9]*" min={0} tabIndex={krutySrpenLimit <= 0 && -9999} id="kruty-srpen"
                           max={krutySrpenLimit}
                           onChange={e => setKrutySrpen(krutySrpenLimit <= 0 ? 0 : e.target.value)}
                           className={krutySrpenLimit <= 0 && 'disabled'} placeholder="Počet" name="kruty-srpen" />
                    }
                    </span>
                  <span>
                  <label htmlFor="kruty-zari">Září {krutyZariLimit <= 0 && "(Vyprodáno)"}</label>
                    {krutyZariLimit > 0 &&
                    <input type="number" pattern="[0-9]*" min={0} id="kruty-zari" tabIndex={krutyZariLimit <= 0 && -9999}
                           max={krutyZariLimit} onChange={e => setKrutyZari(krutyZariLimit <= 0 ? 0 : e.target.value)}
                           className={krutyZariLimit <= 0 && 'disabled'} placeholder="Počet" name="kruty-zari" />
                    }
                    </span>
                  <span>
                  <label htmlFor="kruty-listopad">Listopad {krutyListopadLimit <= 0 && "(Vyprodáno)"}</label>
                    {krutyListopadLimit > 0 &&
                    <input type="number" pattern="[0-9]*" min={0} id="kruty-listopad"
                           tabIndex={krutyListopadLimit <= 0 && -9999} max={krutyListopadLimit}
                           onChange={e => setKrutyListopad(krutyListopadLimit <= 0 ? 0 : e.target.value)}
                           className={krutyListopadLimit <= 0 && 'disabled'} placeholder="Počet"
                           name="kruty-listopad" />
                    }
                    </span>
                </div>
                </section>

                <section>
                  <h2>Kuřata</h2>
                <div>
                  <span>
                  <label htmlFor="kurata-kveten">Květen {kurataKvetenLimit <= 0 && "(Vyprodáno)"}</label>
                    { kurataKvetenLimit > 0 &&
                    <input type="number" pattern="[0-9]*" id="kurata-kveten" tabIndex={kurataKvetenLimit <= 0 && -9999}
                           max={kurataKvetenLimit}
                           onChange={e => setKurataKveten(kurataKvetenLimit <= 0 ? 0 : e.target.value)}
                           className={kurataKvetenLimit <= 0 && 'disabled'} min={0} placeholder="Počet" name="kurata-kveten" />
                    }
                  </span>

                  <span>
                  <label htmlFor="kurata-cerven">Červen {kurataCervenLimit <= 0 && "(Vyprodáno)"}</label>
                    {kurataCervenLimit > 0 &&
                    <input type="number" pattern="[0-9]*" id="kurata-cerven" min={0}
                           tabIndex={kurataCervenLimit <= 0 && -9999} max={kurataCervenLimit}
                           onChange={e => setKurataCerven(kurataCervenLimit <= 0 ? 0 : e.target.value)}
                           className={kurataCervenLimit <= 0 && 'disabled'} placeholder="Počet" name="kurata-cerven" />
                    }
                    </span>
                  <span>
                  <label htmlFor="kurata-cervenec">Červenec {kurataCervenecLimit <= 0 && "(Vyprodáno)"}</label>
                    {kurataCervenecLimit > 0 &&
                    <input type="number" pattern="[0-9]*" id="kurata-cervenec" min={0}
                           tabIndex={kurataCervenecLimit <= 0 && -9999} max={kurataCervenecLimit}
                           onChange={e => setKurataCervenec(kurataCervenecLimit <= 0 ? 0 : e.target.value)}
                           className={kurataCervenecLimit <= 0 && 'disabled'} placeholder="Počet"
                           name="kurata-cervenec" />
                    }
                    </span>
                  <span>
                  <label htmlFor="kurata-srpen">Srpen {kurataSrpenLimit <= 0 && "(Vyprodáno)"}</label>
                    {kurataSrpenLimit > 0 &&
                    <input type="number" pattern="[0-9]*" id="kurata-srpen" tabIndex={kurataSrpenLimit <= 0 && -9999}
                           max={kurataSrpenLimit}
                           onChange={e => setKurataSrpen(kurataSrpenLimit <= 0 ? 0 : e.target.value)}
                           className={kurataSrpenLimit <= 0 && 'disabled'} min={0} placeholder="Počet" name="kurata-srpen" />
                    }
                    </span>
                  <span>
                  <label htmlFor="kurata-zari">Září {kurataZariLimit <= 0 && "(Vyprodáno)"}</label>
                    {kurataZariLimit > 0 &&
                    <input type="number" pattern="[0-9]*" id="kurata-zari" min={0} tabIndex={kurataZariLimit <= 0 && -9999}
                           max={kurataZariLimit}
                           onChange={e => setKurataZari(kurataZariLimit <= 0 ? 0 : e.target.value)}
                           className={kurataZariLimit <= 0 && 'disabled'} placeholder="Počet" name="kurata-zari" />
                    }
                    </span>
                  <span>
                  <label htmlFor="kurata-rijen">Říjen {kurataRijenLimit <= 0 && "(Vyprodáno)"}</label>
                    {kurataRijenLimit > 0 &&
                    <input type="number" pattern="[0-9]*" min={0} id="kurata-rijen" tabIndex={kurataRijenLimit <= 0 && -9999}
                           max={kurataRijenLimit}
                           onChange={e => setKurataRijen(kurataRijenLimit <= 0 ? 0 : e.target.value)}
                           className={kurataRijenLimit <= 0 && 'disabled'} placeholder="Počet" name="kurata-rijen" />
                    }
                    </span>
                  <span>
                  <label htmlFor="kurata-listopad">Listopad {kurataListopadLimit <= 0 && "(Vyprodáno)"}</label>
                    {kurataListopadLimit > 0 &&
                    <input type="number" pattern="[0-9]*" min={0} id="kurata-listopad"
                           tabIndex={kurataListopadLimit <= 0 && -9999} max={kurataListopadLimit}
                           onChange={e => setKurataListopad(kurataListopadLimit <= 0 ? 0 : e.target.value)}
                           className={kurataListopadLimit <= 0 && 'disabled'} placeholder="Počet"
                           name="kurata-listopad" />
                    }
                    </span>
                </div>

</section>

              </div>

                  <button type="submit" className={`button special submit-form ${loading && 'disabled'}`} name="submit">Odeslat objednávku</button>
            </form>
          </section>

        </div>

        <footer id="footer">
        </footer>

      </div>
    </div>
  )
}
